$(document).ready(function() {

    // check if the device is a tablet/phone then change the menu type

    var isMobile = false; //initiate as false
    // device detection
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) {
        isMobile = true;
    }

    //elements variables
    var menu = $("header .navbar");

    if(isMobile == true) {
        // menu.removeClass('navbar-fixed-top');
    }

//smooth scroll on main menu to correspondive section

    $('body').scrollspy({target: ".navbar", offset: 50});

    $(".navbar a, " +
        ".scroll-btn a, " +
        "#play, " +
        "#footer-menu li a, " +
        ".control-btn, " +
        ".orderBtn").on(
            'click',
        function(e) {

            e.preventDefault();
            var hash = $(this).data("target");

            $('html, body').animate({
                scrollTop: $(hash).offset().top
            }, 800, function(){
                window.location.hash = hash;
            });

    });

    if(isMobile == false) {
// add class to navbar on scroll event
        $(window).scroll(function () {
            if ($(window).scrollTop() > 150) {
                $(".navbar").addClass("scrolled");
                $(".cd-top").addClass("cd-visible");
            } else {
                $(".navbar").removeClass("scrolled");
                $(".cd-top").removeClass("cd-visible");
            }
        });
        // check if page is refreshed and no scrolling event has happened - to add / remove class scrolled on navbar
        if ($(window).scrollTop() == 0) {
            $(".navbar").removeClass("scrolled");
            $(".cd-top").removeClass("cd-visible");
        } else {
            $(".navbar").addClass("scrolled");
            $(".cd-top").addClass("cd-visible");
        }
    } else {
        console.log("its not a mobile")
    }

    $(".cd-top").on("click", function(e) {
        e.preventDefault();
        var buttonToTop = $(this).data("target");

        $('html, body').animate({
            scrollTop: $(buttonToTop).offset().top
        }, 800, function(){
            window.location.buttonToTop = buttonToTop;
        });

    });

    $(".flex-centered .btn").on("click", function() {

        var target = $(this).data("target");
        var wrapper = $(".chart-wrapper");
        $(".btn").removeClass("active");
        $(this).addClass("active");
        wrapper.attr("id", "");
        wrapper.attr("id", target);
    });

    $(".more").on("click", function() {
        var target = $(this).data("expand-target");
        if($(target).attr("aria-expanded") == "false") {
            $(target).attr("aria-expanded", "true");
            $(target).css("max-height", "510px");
            $(this).text("Скрий");
        } else {
            $(target).attr("aria-expanded", "false");
            $(target).css("max-height", "160px");
            $(this).text("Виж повече");
        }

    });

    //populate data from cities.json to the selectbox for contact form

    $.ajax({
        url: 'cities.json',
        type: 'get',
        dataType:'JSON',
        data: 'json_data',
        success:function(data) {
            var select = $('#city');
            for (var i = 0; i < data.length; i ++) {
                $(select).append('<option value="' + data[i] + '">' + data[i] + '</option>');
            }
        },
        error: function() {
            $(select).append('<option>' + "Възникна грешка" + '</option>');
        }

    });


});
