$(document).ready(function() {

    $(document).on('keyup',
        '#name, #familyname, #email, #telephone, #zip, #message, #count', false,
        function () {

            var field = $(this);
            var theName = $(field).attr('name');
            var fieldVal = field.val();


        switch (theName) {
            case 'name' :
                validateName(field, fieldVal);
            break;

            case 'familyname' :
                validateFamilyName(field, fieldVal);
            break;

            case 'email' :
                validateMail(field, fieldVal);
            break;

            case 'telephone' :
                validatePhone(field, fieldVal);
            break;

            case 'zip' :
                validateZIP(field, fieldVal);
            break;

            case 'message' :
                validateMessage(field, fieldVal);
            break;

            case 'count' :
                validateCount(field, fieldVal);
            break;

            default :

            break;
        }


        });

    $(document).on('click',
        '#city', false,
        function () {

            var field = $(this);
            var option = $(this);
            var selected = option.find(":selected");
            var isDisabled = selected.is(":disabled");

            validateCity (field, selected, isDisabled);

        });

    $("#sendBTN").on('click', function(e) {
            e.preventDefault();
            validateName('#name', $('#name').val());
            validateFamilyName('#familyname', $('#familyname').val());
            validateMail('#email', $('#email').val());
            validatePhone('#telephone', $('#telephone').val());
            validateZIP('#zip', $('#zip').val());
            validateMessage('#message', $('#message').val());
            validateCount('#count', $('#count').val());
            validateCity ($("#city"), $("#city").find(":selected"), $("#city").find(":selected").is(":disabled"));


           var name = $("#name").val();
           var familyname = $("#familyname").val();
           var email = $("#email").val();
           var telephone = $("#telephone").val();
           var zip = $("#zip").val();
           var message = $("#message").val();
           var count = $("#count").val();
           var city = $("#city").val();
           var googleResponse = $('#g-recaptcha-response').val();

           if (googleResponse == "") {
               alert("Check Recaptcha please!");
           } else {

        $.ajax({
            url: 'back-end/controllers/mailController.php',
            type: 'POST',
            cache: false,
            data: {email: email, name: name, familyname: familyname,telephone: telephone, zip: zip, message: message, count: count, city: city},
            success:function(data) {
                console.log(data);
                if(data == 'error') {

                } else {
                    $("#success-msg").removeClass("hidden");
                }
            },
            error: function(response) {
                console.log(response.responseText);
                $("#success-msg").addClass("hidden");
                alert("Възникна грешка, моля опитайте отново или се свържете с администратор.");
            }

        });
       }
    });

    function validationRegEx(regex, validateInput) {
        var result = false;
        result = regex.test(validateInput);

        return result;
    }

    // validation for inputs separately

    function validateName(field, fieldVal) {
        $(field).val(function(i, v){
            return v.replace(/[a-zA-zа-яА-я]/, function(c){
                return c.toUpperCase().replace(/ /g,'');
            })
        });
        if (fieldVal.length < 3 || fieldVal.length > 20) {
            $("#error-name").html("Моля напишете име, по-голямо от 2 и по-малко от 20 символа.");
            $("#sendBTN").attr('disabled', true);
        } else {
            $("#error-name").html("");
            $("#sendBTN").attr('disabled', false);
        }
    }

    function validateFamilyName(field, fieldVal) {
        $(field).val(function(i, v){
            return v.replace(/[a-zA-zа-яА-я]/, function(c) {
                return c.toUpperCase().replace(/ /g,'');
            })
        });
        if (fieldVal.length < 3 || fieldVal.length > 20) {
            $("#error-familyname").html("Моля напишете фамилно име, по-голямо от 2 и по-малко от 20 символа.");
            $("#sendBTN").attr('disabled', true);
        } else {
            $("#error-familyname").html("");
            $("#sendBTN").attr('disabled', false);
        }
    }

    function validateMail(field, fieldVal) {
        var mailRegEx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        var regexResult = validationRegEx(mailRegEx, fieldVal);

        if (!regexResult) {
            $("#error-email").html("Моля напишете валиден мейл адрес.");
            $("#sendBTN").attr('disabled', true);
        } else {
            $("#error-email").html("");
            $("#sendBTN").attr('disabled', false);
        }
    }

    function validatePhone(field, fieldVal) {
        var phoneRegEx = /^\+?([0-9]{1,3})\)?[- ]?([0-9]{9})$/;
        var phoneMatch = validationRegEx(phoneRegEx, fieldVal);

        if(!phoneMatch) {
            $("#error-telephone").html("Моля въведете валиден телефонен номер.");
            $("#sendBTN").attr('disabled', true);
        } else {
            $("#error-telephone").html("");
            $("#sendBTN").attr('disabled', false);
        }
    }

    function validateZIP(field, fieldVal) {
        var zipRegEx = /\d{4}/;
        var zipMatch = validationRegEx(zipRegEx, fieldVal);

        if(!zipMatch || fieldVal.length >= 5) {
            $("#error-zip").html("Моля въведете валиден пощенски код.");
            $("#sendBTN").attr('disabled', true);
        } else {
            $("#error-zip").html("");
            $("#sendBTN").attr('disabled', false);
        }
    }

    function validateMessage(field, fieldVal) {
        if(fieldVal.length <= 10){
            $("#error-message").html("Моля въведете съобщение с точен адрес, по-голямо от 10 символа.");
            $("#sendBTN").attr('disabled', true);
        } else {
            $("#error-message").html("");
            $("#sendBTN").attr('disabled', false);
        }
    }

    function validateCount(field, fieldVal) {
        if(fieldVal < 1) {
            $("#error-count").html("Изберете брой шишета за доставката Ви.");
            $("#sendBTN").attr('disabled', true);
        } else {
            $("#error-count").html("");
            $("#sendBTN").attr('disabled', false);
        }
    }

    function validateCity(field, selected, isDisabled) {
        if(isDisabled) {
            $("#error-city").html("Моля изберете град.");
            $("#sendBTN").attr('disabled', true);
        } else {
            $("#error-city").html("");
            $("#sendBTN").attr('disabled', false);
        }
    }

});